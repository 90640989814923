import swal from "sweetalert";
import Swal from "sweetalert2";
import axios from "axios";
import {
  IS_LOADING,
  baseURL,
  Login_URL,
  LoginReg,
  userdetails_URL,
  userdetailsReg,
  confirmUser_URL ,
  confirmUserReg,
 Accept_URL , AcceptReg, Reject_URL , RejectReg, confirmUserExcel_URL, userdetailsFilter_URL, filterUserReg,
  getStatesReg, getStates_URL, candidateEdit_URL , candidateEditReg, candidateEditSubmitReg, candidateEditSubmit_URL

} from "../../Constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function getAsios() {
  let token = localStorage.getItem("token1");

  if (token) {
    return axios.create({
      baseURL: baseURL,
      headers: {
        Authorization: ` ${token}`,
      },
    });
  } else {
    return axios.create({
      baseURL: baseURL,

      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("token", JSON.stringify(tokenDetails));
}

export function dispatchLoadingStatus(loading) {
  return function (dispatch, getState) {
    dispatch({ type: IS_LOADING, payload: { loading: loading } });
  };
}


export function LoginAPI(data, email, name) {
  return function (dispatch) {
    return getAsios()
      .post(Login_URL, data)
      .then((response) => {
      debugger

        dispatch(dispatchLoadingStatus(false));

        console.log("response.errorcode" , response.data.errorcode);

        if (response.data.errorcode === 0) {
          debugger;

          dispatch({ type: LoginReg, payload: response.data });

          toast.success("Login Successfully");  

          localStorage.setItem("emailAdminn", email);

          localStorage.setItem(Login_URL, true);
          localStorage.setItem("token1", response.data._token);
          return;
          // localStorage.setItem("newRoleVissible", response.data.data.roles);
          // localStorage.setItem("nameAdmin", response.data.data.name);

        } else {
          dispatch(dispatchLoadingStatus(false));
          swal("", response.data.message, "error");
          return;

        }
      })

      .catch((err) => {
      debugger

        dispatch(dispatchLoadingStatus(false));
        swal("", "Invalid credentials", "error");
        return;

      });
  };
}



export function userdetailsAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(userdetails_URL, data)
      .then((response) => {
        debugger
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          localStorage.setItem("userdetailsAPI", false);
          dispatch({ type: userdetailsReg, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        localStorage.setItem("userdetailsAPI", false);
        // swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function AcceptAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(Accept_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.statusCode == 200) {



          Swal.fire({
            title: 'Request accepted',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              // Redirect to another page here
          window.location.href = window.location.origin + "/List";


            }
          })

     
          dispatch({ type: AcceptReg, payload: response.data });

         
        }
        // return;
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
    
        // swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function RejectAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(Reject_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.statusCode == 200) {

          Swal.fire({
            title: 'Request rejected',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              // Redirect to another page here
          window.location.href = window.location.origin + "/List";


            }
          })
          dispatch({ type: RejectReg, payload: response.data });
        }
        // return;
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
    
        // swal("", "Something went wrong, please try again", "error");
      });
  };
}



export function confirmUserAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(confirmUser_URL, data)
      .then((response) => {
        console.log("confirmUser_URL" , response);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.statusCode == 200) {
          dispatch({ type: confirmUserReg, payload: response.data });
        }
        // return;
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", "Something went wrong, please try again", "error");
      });
  };
}


export function confirmUserExcelAPI(data) {
  return function (dispatch) {
    return getAsios()
      .get(confirmUserExcel_URL, data)
      .then((response) => {
        console.log("confirmUserExcel_URL" , response);
        dispatch(dispatchLoadingStatus(false));
        // window.open(response.data)
      
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function userdetailsFilterAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(userdetailsFilter_URL, data)
      .then((response) => {
        debugger
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          console.log("filter user" , response.data);
          localStorage.setItem("userdetailsFilterAPI", false);
          dispatch({ type: filterUserReg, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        localStorage.setItem("userdetailsFilterAPI", false);
        // swal("", "Something went wrong, please try again", "error");
      });
  };
}




export function getStatesAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getStates_URL, data)
      .then((response) => {
      
        dispatch(dispatchLoadingStatus(false));
        if (response.data.statusCode == 200) {
       
          dispatch({ type: getStatesReg, payload: response.data.States });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", "Something went wrong, please try again", "error");
      });
  };
}


export function candidateEditAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(candidateEdit_URL, data)
      .then((response) => {
        debugger
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          console.log("stateee" , response.data.result[0]);
       
          dispatch({ type: candidateEditReg, payload: response.data.result[0] });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", "Something went wrong, please try again", "error");
      });
  };
}


export function candidateEditSubmitAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(candidateEditSubmit_URL, data)
      .then((response) => {
        debugger
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          
          // window.location.href = window.location.origin + '/List';

          swal("Edit Details!", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/List";
          });

          dispatch({ type: candidateEditSubmitReg, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", "Something went wrong, please try again", "error");
      });
  };
}





