
export const baseURL = "https://aibe-api.smartexams.in/admin/";

export const Login_URL = "login";
export const LoginReg = "loginReg";
export const IS_LOADING = "IS_LOADING";


export const confirmUser_URL = "edit-user-request"
export const confirmUserReg = "confirmUserReg";

export const userdetails_URL = "user-details"
export const userdetailsReg = "userdetailsReg";

export const Accept_URL = "edit-request-approve"
export const AcceptReg = "AcceptReg";

export const Reject_URL = "edit-request-reject"
export const RejectReg = "RejectReg";


export const confirmUserExcel_URL = "confirm-user-excel"
export const confirmUserExcelReg = "confirmUserExcelReg";


export const userdetailsFilter_URL = "filter-user"
export const filterUserReg = "filterUserReg";

export const getStates_URL = "get-states"
export const getStatesReg = "getStatesReg";


export const candidateEdit_URL = "candidate-edit"
export const candidateEditReg = "candidateEditReg";

export const candidateEditSubmit_URL = "candidate-edit-submit"
export const candidateEditSubmitReg = "candidateEditSubmitReg";



