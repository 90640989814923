import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmUserExcelAPI, dispatchLoadingStatus, userdetailsAPI, userdetailsFilterAPI } from '../API/Action/globalAction';
import Loader from './Loader';
import { useNavigate } from "react-router-dom";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import MainDashbord from "./MainDashbord";
import Select from 'react-select';



function Report() {

    let AllFile = 'https://aibe-api.smartexams.in/admin/all-user-excel';

    let ConfirmUsersFile = 'https://aibe-api.smartexams.in/admin/confirm-user-excel';
    let EditRequestUsersFile = 'https://aibe-api.smartexams.in/admin/edit-user-excel';
    let EditRequestAcceptedUserFile = 'https://aibe-api.smartexams.in/admin/approve-user-excel';
    let EditRequestRejectedUserFile = 'https://aibe-api.smartexams.in/admin/reject-user-excel';

    const dispatch = useDispatch({

    });
    let history = useNavigate();
    let filterUserReg = useSelector(state => state.globalReducer.filterUserReg);
    let total = filterUserReg ? filterUserReg.result_count : "";
    const [page, setpage] = useState(1);
    const [allData22, setAllData22] = useState();
    let token = localStorage.getItem('token1');
    const [frname, setFRname] = useState('');

    let Filter = [{ label: "Confirm Users", value: "1" }, { label: "Edit Request Users", value: "2" },
    { label: "Edit Request Accepted User", value: "3" }, { label: "Edit Request Rejected User", value: "4" }]

    const [filter, setfilter] = useState('0');
    const [filter22, setfilter22] = useState({ label: "All", value: "0" });

    useEffect(() => {
        if (frname.length > 0) {
            setpage(1)
            Apicall(0)
        }
    }, [frname])

    useEffect(() => {
        if ( filter.length > 0) {

            setpage(1)
            Apicall(0)
        }
    }, [filter])

    function handleExcel() {
        let data = {
            'token': token ? token : "",
        }
        dispatch(confirmUserExcelAPI())

    }

    function Apicall(start) {

        if (localStorage.getItem('userdetailsFilterAPI') != "true") {
            localStorage.setItem('userdetailsFilterAPI', true)

            let data = {
                'token': token ? token : "",
                'start': start,
                'limit': 50,
                'flag': filter ? filter : "0"



            }
            dispatch(dispatchLoadingStatus(true))
            dispatch(userdetailsFilterAPI(data))
        }

    }

    useEffect(() => {
        localStorage.removeItem("roll");


        let data = {
            'token': token ? token : "",
            'start': 0,
            'limit': 50,
            'flag': filter
        }
        dispatch(dispatchLoadingStatus(true))
        dispatch(userdetailsFilterAPI(data))

    }, [])


    useEffect(() => {
        if (filterUserReg) {
            if (filterUserReg.errorcode === 1) {
                setAllData22([]);
            } else {
                console.log("dataaassss", filterUserReg);
                setAllData22(filterUserReg ? filterUserReg.result : filterUserReg.result);
            }
        }

    }, [filterUserReg])

    const [order, setOrder] = useState("ASC");

    const Sortt = (col) => {
        if (order === "ASC") {
            const sorted = [...allData22].sort((a, b) =>
                a[col] > b[col] ? 1 : -1
            );
            setAllData22(sorted);
            setOrder("DSC");
        }

        if (order === "DSC") {
            const sorted = [...allData22].sort((a, b) =>
                a[col] < b[col] ? 1 : -1
            );
            setAllData22(sorted);
            setOrder("ASC");
        }


    }

    useEffect(() => {
        localStorage.setItem("pages", "1");


        if (token) {
            if (!window.location.href.includes('Report')) {
                window.location.href = window.location.origin + '/Report'
            }
        }
        else {
            if (!window.location.href.includes('/Login')) {
                window.location.href = window.location.origin + '/Login'
            }
        }

    }, [])

    let confirmUserReg = useSelector(state => state.globalReducer.confirmUserReg);

    function handleView(rollNumber, i) {

        if (rollNumber) {
            localStorage.setItem("roll", rollNumber)
            history('/Userdetails', {
                state: i
            })
        }
    }

    useEffect(() => {
        if (confirmUserReg) {
            console.log("confirmUserReg", confirmUserReg);
            if (confirmUserReg.statusCode == 200) {
                debugger
                history('/Userdetails', { state: confirmUserReg })
            }
        }
    }, [confirmUserReg])

    return (
        <>
            <Loader />
            <MainDashbord />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Report</h4>
                                </div>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-lg-3">
                                <div className='card mini-stats-wid'>
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">Confirm users</p>
                                                <h4 className="mb-0"> {filterUserReg ? filterUserReg.confirm_result_count : "0"} </h4>
                                            </div>
                                            <div className="flex-shrink-0 align-self-center">
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                    <span className="avatar-title">
                                                        <i className="bx bx-user font-size-24"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className='card mini-stats-wid'>
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">Edit request users</p>
                                                <h4 className="mb-0"> {filterUserReg ? filterUserReg.edit_request_count : "0"} </h4>
                                            </div>
                                            <div className="flex-shrink-0 align-self-center">
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                    <span className="avatar-title">
                                                        <i className="bx bx-user font-size-24"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className='card mini-stats-wid'>
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">Edit Request Accepted User</p>
                                                <h4 className="mb-0"> {filterUserReg ? filterUserReg.approve_count : "0"} </h4>
                                            </div>
                                            <div className="flex-shrink-0 align-self-center">
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                    <span className="avatar-title">
                                                        <i className="bx bx-user font-size-24"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-3">
                                <div className='card mini-stats-wid'>
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">Edit Request Rejected User</p>
                                                <h4 className="mb-0"> {filterUserReg ? filterUserReg.reject_count : "0"} </h4>
                                            </div>
                                            <div className="flex-shrink-0 align-self-center">
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                    <span className="avatar-title">
                                                        <i className="bx bx-user font-size-24"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="row">

                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">

                                        <div className="table-rep-plugin">
                                            <div className="row mb-3 no-gutters">

                                                <div className="col-md-12">

                                                    <div className='row align-items-center'>

                                                        <div className='col-md-3 col-6 mb-4'>
                                                            <Select
                                                                options={Filter ? [{ label: 'All', value: '0' }].concat(Filter.map(e => ({ label: e.label, value: e.value }))) : []}
                                                                isClearable={false}
                                                                placeholder={"All"}
                                                                value={filter22 ? filter22.value == "" ? [{ label: 'Filter', value: '0' }] : filter22 : ""}
                                                                onChange={(e) => { setfilter(e.value); setfilter22({ label: e.label, value: e.value }) }} />

                                                        </div>
                                                        <div className='col-md-9'>
                                                            <button className=" btn btn-primary float-end waves-effect waves-light"  style={{marginBottom:'28px'}}onClick={()=>filter == '0' ?
                                                            window.open(AllFile) :
                                                             filter == '1' ?
                                                             window.open(ConfirmUsersFile) :  filter == '2' ?
                                                             window.open(EditRequestUsersFile) : filter == '3' ?
                                                             window.open(EditRequestAcceptedUserFile) : filter == '4' ?
                                                             window.open(EditRequestRejectedUserFile) : ""
                                                             
                                                             
                                                             }><i className="bx bx-download font-size-17"></i> Download</button>

                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                        <div className="table-rep-plugin"  >
                                            <div className="table-responsive">
                                                <table id="example" className="table table-striped dt-responsive nowrap w-100">
                                                    <thead>
                                                        <tr>

                                                            <th>Sr No.</th>
                                                            <th>Name  <i onClick={() => Sortt("Candidate_Name")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5, cursor: "pointer" }}></i></th>
                                                            <th>Father Name <i onClick={() => Sortt("Father_Name")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5, cursor: "pointer" }}></i></th>
                                                            <th>Roll Number <i onClick={() => Sortt("Roll_Number")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5, cursor: "pointer" }}></i></th>
                                                            <th>Email <i onClick={() => Sortt("Email")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5, cursor: "pointer" }}></i> </th>
                                                            <th data-priority="1">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {allData22 && allData22.map((i, index) =>
                                                            <tr>
                                                                <td><h6 className="font-weight-bold mb-1 mt-2"> {((page * 50) + index + 1) - 50}.</h6></td>
                                                                <td><div className="d-flex ">
                                                                    <div className="flex-shrink-0 me-3 mt-0">
                                                                        <Zoom ><img className="d-flex-object rounded-circle avatar-xs" alt="" src={i.profiepic ? i.profiepic : "images/black22.jpg"} /></Zoom>
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="font-weight-bold mb-1 mt-2">{i.Candidate_Name}</h6>
                                                                    </div>
                                                                </div>  </td>
                                                                <td>
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="font-weight-bold mb-1 mt-2">{i.Father_Name}</h6>
                                                                    </div>
                                                                </td>
                                                                <td className='mt-2'><h6 className="font-weight-bold mb-1 mt-2">{i.Roll_Number}</h6></td>
                                                                <td className='mt-2'><h6 className="font-weight-bold mb-1 mt-2"> {i.Email}</h6></td>
                                                                <td>  <button type="button" onClick={() => {
                                                                    localStorage.setItem("projectTitle33", "")
                                                                    localStorage.setItem("projectTitle44", "Project"); handleView(i.Roll_Number, i)
                                                                }} className="btn btn-primary  waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#staticBackdrop22"  >
                                                                    View
                                                                </button>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>

                                                {allData22 ? allData22.length > 0 ? null : <div style={{ textAlign: 'center' }} ><p>No data found</p> </div> : null}

                                            </div>

                                            <div className=' btns-table align-items-center justify-content-end'>
                                                {filterUserReg ? filterUserReg.result_count > 0 ? <div className="row align-items-center wrapper-pg-main">
                                                    <div className='col-6'>
                                                        {/* Showing {page} of {Math.ceil(totalPages)} Pages */}
                                                        {/* Showing 1 to {allData22 ? allData22.length : ""} of {getadminattendancelistRes ? getadminattendancelistRes.count : ""} entries */}
                                                        Showing {page <= 1 ? 1 : (page - 1) * 50 + 1} to {page * 50 <= total ? page * 50 : total}  of {filterUserReg ? filterUserReg.result_count : ""} entries


                                                    </div>
                                                    <div className='col-6'>

                                                        <div className="wrapper-pgn">
                                                            <div className="pgn pgn-default">


                                                                {filterUserReg.result_count >= 50 ?
                                                                    <div className="prev " onClick={() => document.getElementById('content').scrollBy(-50, 0)}><i className="bx bx-chevrons-left" style={{ cursor: "pointer" }}></i></div>
                                                                    : ""}
                                                                <div className="pages" id="content">
                                                                    {(() => {
                                                                        const pages = [];

                                                                        for (let i = 1; i < (filterUserReg.result_count / 50) + 1; i++) {
                                                                            pages.push(<div className={page == i ? "page page-btn active  p-2" : "page page-btn p-2"} onClick={() => { Apicall((i - 1) * 50); setpage(i) }}>{i}</div>);
                                                                        }

                                                                        return pages;
                                                                    })()}
                                                                </div>
                                                                {filterUserReg.result_count >= 50 ?
                                                                    <div onClick={() => document.getElementById('content').scrollBy(50, 0)} className=" next "><i className="bx bx-chevrons-right" style={{ cursor: "pointer" }}></i></div>
                                                                    : ""}

                                                            </div> </div>
                                                    </div>

                                                </div> : null : null}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>


            <script src="%PUBLIC_URL%/libs/magnific-popup/jquery.magnific-popup.min.js"></script>


            <script src="%PUBLIC_URL%/js/pages/lightbox.init.js"></script>

            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            © All India Bar Examination  Rights Reserved {(new Date().getFullYear())}.
                        </div>
                        <div className="col-sm-6">
                            <div className="text-sm-end d-none d-sm-block">
                                Powered by  <a href="https://www.nichetechsolutions.com/" target="_blank">Nichetech.in</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Report