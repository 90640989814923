
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import { dispatchLoadingStatus, confirmUserAPI, AcceptAPI, RejectAPI } from '../API/Action/globalAction';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainDashbord from './MainDashbord';
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css';
import pdf from './pdf.png';
import Loader from './Loader';

toast.configure();

// Added by Parth Roka
function ProfileList(props) {
    const convertToPdf = () => {
        try {
            // Create a new Blob object from the base64 data
            const byteCharacters = atob(CandidateImg.image_url.split(',')[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            // Create a URL for the Blob
            const pdfUrl = URL.createObjectURL(blob);

            // Open the PDF in a new tab for the user to download
            window.open(pdfUrl, '_blank');
            debugger
        } catch (error) {
            console.error('Error converting to PDF:', error);
        }
    }

    let pages = localStorage.getItem("pages");


    const convertToPdffather = () => {
        try {
            // Create a new Blob object from the base64 data
            const byteCharacters = atob(fatherImg.image_url.split(',')[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            // Create a URL for the Blob
            const pdfUrl = URL.createObjectURL(blob);

            // Open the PDF in a new tab for the user to download
            window.open(pdfUrl, '_blank');
            debugger
        } catch (error) {
            console.error('Error converting to PDF:', error);
        }
    }

    const convertToPdfStateofBar = () => {
        try {
            // Create a new Blob object from the base64 data
            const byteCharacters = atob(StateofBarCouncil.image_url.split(',')[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            // Create a URL for the Blob
            const pdfUrl = URL.createObjectURL(blob);

            // Open the PDF in a new tab for the user to download
            window.open(pdfUrl, '_blank');
            debugger
        } catch (error) {
            console.error('Error converting to PDF:', error);
        }
    }

    const convertToPdfdobImg = () => {
        try {
            // Create a new Blob object from the base64 data
            const byteCharacters = atob(dobImg.image_url.split(',')[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            // Create a URL for the Blob
            const pdfUrl = URL.createObjectURL(blob);

            // Open the PDF in a new tab for the user to download
            window.open(pdfUrl, '_blank');
            debugger
        } catch (error) {
            console.error('Error converting to PDF:', error);
        }
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function modelClose() {

        document.getElementById("close").click()
    }
    const token = localStorage.getItem('token1');
    const [allData22, setAllData22] = useState();
    const [allData33, setAllData33] = useState();
    const [CandidateImg, setCandidateImg] = useState();
    const [fatherImg, setFatherImg] = useState();
    const [StateofBarCouncil, setStateofBarCouncil] = useState();
    const [EnrollmentNo, setEnrollmentNo] = useState();
    const [dobImg, setDobImg] = useState();

    useEffect(() => {
        window.scrollTo(0, 0)

        window.onpopstate = function (event) {
            window.history.pushState(null, null, window.location.href = window.location.origin + '/List');
        };

    }, [])

    const { state } = useLocation();
    console.log("state" , state ? state : "");
    let confirmUserReg = useSelector(state => state.globalReducer.confirmUserReg);

    const rollNumber = localStorage.getItem("roll");
    useEffect(() => {
        if(pages == '0'){

        if (rollNumber) {

            let data = {
                'token': token ? token : "",
                'roll_no': rollNumber
            }
            dispatch(dispatchLoadingStatus(true))
            dispatch(confirmUserAPI(data))
            // Edit API call and show data
        }
    }

    }, [rollNumber])

    useEffect(() => {
        if (confirmUserReg) {
            console.log("confirmUserReg", confirmUserReg);
            if (confirmUserReg.statusCode == 200) {
                setAllData22(confirmUserReg ? confirmUserReg.user : []);
                setAllData33(confirmUserReg ? confirmUserReg.edit_request : []);

                setCandidateImg(confirmUserReg ? confirmUserReg.edit_request.Doc_for_candidate_name : []);
                setDobImg(confirmUserReg ? confirmUserReg.edit_request.Doc_for_dob : []);
                setFatherImg(confirmUserReg ? confirmUserReg.edit_request.Doc_for_father_name : []);
                setStateofBarCouncil(confirmUserReg ? confirmUserReg.edit_request.Doc_for_State_of_Bar_Council : []);
                setEnrollmentNo(confirmUserReg ? confirmUserReg.edit_request.Doc_for_Enrollment_no : []);


            }
        }
    }, [confirmUserReg])

    const [reason, setReason] = useState("");
    const [reasonError, setReasonError] = useState(false);
    const [ReqAccepts, setReqAccepts] = useState("");
    const [ReqRejects, setReqRejects] = useState("");

    const handleReason = (e) => {
        setReason(e.target.value.trim() == "" ? "" : e.target.value);

        if (e.target.value == "") {
            setReasonError(<p className='error' >Reason is required</p>);
        }
        else {
            setReasonError("");
        }
    }

    const dispatch = useDispatch({

    });

    let history = useNavigate();

    const Accept = (event) => {
        if (token && rollNumber) {
            Swal.fire({
                title: "Do you want to accept the edit request ?",
                // text: "Do you want to accept edit request?",
                showCancelButton: true, // Show the "Cancel" button
                showConfirmButton: true, // Show the "Ok" button
                cancelButtonText: "Cancel", // Customize the "Cancel" button text
                confirmButtonText: "Ok",   // Customize the "Ok" button text
                allowOutsideClick: false,

            }).then((result) => {
                if (result.isConfirmed) {
                    let data = {
                        'roll_no': rollNumber,
                        'token': token ? token : "",
                    }

                    dispatch(dispatchLoadingStatus(true))
                    dispatch(AcceptAPI(data));

                    setReqAccepts("1")

                }
            })



        }


        else {
            Swal("", "Something Wrong!", "error");

        }


    }

    const Reject = () => {
        if (reason == "") {
            setReasonError(<p className='error'>Reason is required</p>);
        }
        else {
            setReasonError("");
        }
        if (reason) {
            if (token && rollNumber) {
                let data = {
                    'roll_no': rollNumber,
                    'reason': reason,
                    'token': token ? token : "",
                }
                dispatch(dispatchLoadingStatus(true))
                dispatch(RejectAPI(data));
                setReqRejects("2")
            }
        }
    }

    useEffect(() => {
        setReason("");
        const token = localStorage.getItem('token1');
        if (token && state) {
            if (!window.location.href.includes('Userdetails')) {
                // window.location.href = window.location.origin + '/Userdetails'
                history('/Userdetails');
            }
        }
        else {
            if (!window.location.href.includes('/Login')) {
                // window.location.href = window.location.origin + '/Login'
                history('/Login');
            }
        }
    }, [])

    return (

        <div id="layout-wrapper">
            <Loader />

            <MainDashbord />

            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">


                        <div className="row">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">User Details </h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">

                                        <a href='/List' className="breadcrumb-item active">User List </a> <a href='/Userdetails' className="breadcrumb-item active">User Details </a>

                                    </ol>
                                </div>

                            </div>
                        </div>


                        <div className="row">
                            <div className={pages == '1' ? 'col-md-12' : 'col-md-6' }>
                                <div className="card overflow-hidden">

                                    <div className="card-body pt-0">
                                        <div className="row">
                                        {pages == '0'  ? 
                                            <div className="col-sm-12">
                                                <div className="avatar-md profile-user-wid freelancer-profile">
                                                    <img src={allData22 ? allData22.Photo : state ? state.Photo : '/images/black22.jpg'} alt="" className="img-thumbnail rounded-circle" />
                                                </div>
                                            </div>
                                            : "" }
                                             <div className="table-responsive main-table-new">
                                                <table className="table table-responsive mb-0">
                                                    <tbody>

                                                        <tr>
                                                            <th scope="row">Candidate Name :</th>
                                                            <td>{allData22 ? allData22.Candidate_Name : state ? state.Candidate_Name : '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Father Name :</th>
                                                            <td>{allData22 ? allData22.Father_Name : state ? state.Father_Name : '-'}</td>
                                                        </tr>


                                                        <tr>
                                                            <th scope="row">Mobile :</th>
                                                            <td>+91{allData22 ? allData22.Phone_number : state ? state.Phone_number : '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">E-mail :</th>
                                                            <td>{allData22 ? allData22.Email : state ? state.Email : '-'}</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row">Phone Number :</th>
                                                            <td>{allData22 ? allData22.Phone_number : state ? state.Phone_number : '-'}</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row">Application ID:</th>
                                                            <td>{allData22 ? allData22.Application_ID : state ? state.Application_ID : '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Roll Number:</th>
                                                            <td>{allData22 ? allData22.Roll_Number : state ? state.Roll_Number : '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Enrollment Number:</th>
                                                            <td>{allData22 ? allData22.Enrollment_Certificates : state ? state.Enrollment_Certificates : '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Date Of Birth :</th>
                                                            {/* <td>{allData22 ? moment(allData22.Date_of_Birth).format('DD/MM/YYYY') : '-'}</td> */}
                                                            <td>{allData22 ? allData22.Date_of_Birth : state ? state.Date_of_Birth : '-'}</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row">Confirmation Date Time:</th>
                                                            <td>{allData22 ? moment(allData22.Confirmation_date_time).format('DD/MM/YYYY') : state ? moment(state.Confirmation_date_time).format('DD/MM/YYYY'): '-'}</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row">Edit Date Time:</th>
                                                            <td>{allData22 ? moment(allData22.Edit_confirmation_date_time).format('DD/MM/YYYY') : state ? moment(state.Edit_confirmation_date_time).format('DD/MM/YYYY') : '-'}</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row">Maximum Marks:</th>
                                                            <td>{allData22 ? allData22.Maximum_Marks : state ? state.Maximum_Marks : '-'}</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row">Obtained Marks:</th>
                                                            <td>{allData22 ? allData22.Obtained_Marks : state ? state.Obtained_Marks : '-'}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>


                                        </div>
                                    </div>
                                </div>


                            </div>

                                {pages == '0'  ? 
                            <div className="col-md-6">
                                <div className="card overflow-hidden">

                                    <div className="card-body pt-0">
                                        <div className="row">

                                            <div className="table-responsive">
                                                <table className="table table-responsive align-middle mt-3 mb-0">

                                                    <tbody>

                                                        <tr>
                                                            <td>
                                                                <div className='d-flex'>
                                                                    <div className='req-heading-div'>
                                                                        <h4 className="mb-sm-0  font-size-18" >Edit Request </h4>
                                                                    </div>
                                                                    <button type="button" className="btn btn-success me-2  waves-effect waves-light" id="sa-success-grt" onClick={Accept} >
                                                                        <i className="mdi mdi-check"></i> Accept
                                                                    </button>
                                                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => { handleShow(); setReasonError(""); setReason("") }}>
                                                                        <i className="mdi mdi-close"></i>Reject
                                                                    </button>
                                                                </div>


                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>


                                            </div>

                                            <div className="col-sm-12">
                                                <h4 className="mb-sm-0  ms-2 mt-3 font-size-18">Edit Details </h4>
                                                <div className="avatar-md profile-user-wid freelancer-profile">
                                                    <img src={allData22 ? allData22.Photo : '/images/black22.jpg'} alt="" className="img-thumbnail rounded-circle" />
                                                </div>
                                            </div> <div className="table-responsive">
                                                <table className="table table-responsive mb-0">
                                                    <tbody>

                                                        <tr>
                                                            <th scope="row">Application ID:</th>
                                                            <td>{allData33 ? allData33.Application_ID ? allData33.Application_ID : '-' : '-'}</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row">Roll Number:</th>
                                                            <td>{allData33 ? allData33.Roll_Number ? allData33.Roll_Number : '-' : '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Enrollment Number:</th>
                                                            <td>{allData33 ? allData33.Updated_Enrollment_Number ? allData33.Updated_Enrollment_Number : '-' : '-'}</td>

                                                            <div className="flex-shrink-0 me-3">



                                                                {EnrollmentNo ? EnrollmentNo.image_url.startsWith("data:application/pdf") ?
                                                                    <a onClick={() => convertToPdf()} target='_black'> <img src={pdf} style={{ height: '10%', width: '10%', cursor: "pointer" }} /></a> :
                                                                    EnrollmentNo.image_url.includes(".pdf") ? <a href={EnrollmentNo ? EnrollmentNo.image_url ? EnrollmentNo.image_url : "" : ""} target='_black'> <img src={pdf} style={{ height: '10%', width: '10%', cursor: "pointer" }} /></a> :

                                                                    <Zoom >  <img className="d-flex-object rounded-circle avatar-xs" alt="" src={EnrollmentNo ? EnrollmentNo.image_url ? EnrollmentNo.image_url : "images/black22.jpg" : "images/black22.jpg"} /></Zoom> : null}

                                                            </div>

                                                        </tr>
                                                        <tr>

                                                            <th scope="row">Candidate Name :</th>
                                                            <td>{allData33 ? allData33.Candidate_Name ? allData33.Candidate_Name : '-' : '-'}</td>
                                                            <div className="flex-shrink-0 me-3">

                                                                {/* {pdfData  ? 
                                                                <a onClick={() => convertToPdf()} target='_black'> <img src={pdf} style={{ height: '10%', width: '10%' }} /></a>  : null} */}


                                                                {CandidateImg ? CandidateImg.image_url.startsWith("data:application/pdf") ?
                                                                    <a onClick={() => convertToPdf()} target='_black'> <img src={pdf} style={{ height: '10%', width: '10%', cursor: "pointer" }} /></a> :
                                                                    CandidateImg.image_url.includes(".pdf") ? <a href={CandidateImg ? CandidateImg.image_url ? CandidateImg.image_url : "" : ""} target='_black'> <img src={pdf} style={{ height: '10%', width: '10%', cursor: "pointer" }} /></a> :


                                                                    <Zoom >  <img className="d-flex-object rounded-circle avatar-xs" alt="" src={CandidateImg ? CandidateImg.image_url ? CandidateImg.image_url : "images/black22.jpg" : "images/black22.jpg"} /></Zoom> : null}

                                                            </div>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">S/o, D/o, W/o</th>
                                                            <td>{allData33 ? allData33.Father_Name ? allData33.Father_Name : '-' : '-'}</td>
                                                            <div className="flex-shrink-0 me-3">
                                                                {fatherImg ? fatherImg.image_url.startsWith("data:application/pdf") ?
                                                                    <a onClick={() => convertToPdffather()} target='_black'> <img src={pdf} style={{ height: '10%', width: '10%', cursor: "pointer" }} /></a> :
                                                                    fatherImg.image_url.includes(".pdf") ? <a href={fatherImg ? fatherImg.image_url ? fatherImg.image_url : "" : ""} target='_black'> <img src={pdf} style={{ height: '10%', width: '10%', cursor: "pointer" }} /></a> :


                                                                    <Zoom >  <img className="d-flex-object rounded-circle avatar-xs" alt="" src={fatherImg ? fatherImg.image_url ? fatherImg.image_url : "images/black22.jpg" : "images/black22.jpg"} /></Zoom> : null}
                                                            </div>
                                                        </tr>

                                                        {/* <tr>
                                                            <th scope="row">State Bar Council :</th>
                                                            <td>{allData33 ? allData33.State_of_Bar_Council ? allData33.State_of_Bar_Council : '-' : '-'}</td>
                                                            <div className="flex-shrink-0 me-3">
                                                                {StateofBarCouncil ? StateofBarCouncil.image_url.startsWith("data:application/pdf") ?
                                                                    <a onClick={() => convertToPdfStateofBar()} target='_black'> <img src={pdf} style={{ height: '10%', width: '10%', cursor: "pointer" }} /></a> :

                                                                    <Zoom >  <img className="d-flex-object rounded-circle avatar-xs" alt="" src={StateofBarCouncil ? StateofBarCouncil.image_url ? StateofBarCouncil.image_url : "images/black22.jpg" : "images/black22.jpg"} /></Zoom> : null}
                                                            </div>
                                                        </tr> */}



                                                        {/* <tr>
                                                            <th scope="row">Mobile :</th>
                                                            <td>{allData33 ? allData33.Phone_number ? "+91" : "" : ""}{allData33 ? allData33.Phone_number ? allData33.Phone_number : "-" : "-"}</td>
                                                        </tr> */}
                                                        {/* <tr>
                                                            <th scope="row">E-mail :</th>
                                                            <td>{allData33 ? allData33.Email ? allData33.Email : '-' : '-'}</td>
                                                        </tr> */}
                                                        {/* <tr>
                                                            <th scope="row">Phone Number :</th>
                                                            <td>{allData33 ? allData33.Phone_number ? allData33.Phone_number : '-' : '-'}</td>
                                                        </tr> */}

                                                        {/* <tr>
                                                            <th scope="row">Application ID:</th>
                                                            <td>{allData33 ? allData33.Application_ID ? allData33.Application_ID : '-' : '-'}</td>
                                                        </tr> */}
                                                        {/* <tr>
                                                            <th scope="row">Roll Number:</th>
                                                            <td>{allData33 ? allData33.Roll_Number ? allData33.Roll_Number : '-' : '-'}</td>
                                                        </tr> */}
                                                        {/* <tr>
                                                            <th scope="row">Enrollment Number:</th>
                                                            <td>{allData33 ? allData33.Enrollment_Certificates ? allData33.Enrollment_Certificates : '-' : '-'}</td>
                                                        </tr> */}
                                                        <tr>
                                                            <th scope="row">Date Of Birth :</th>
                                                            <td>{allData33 ? allData33.Date_of_Birth ? allData33.Date_of_Birth : '-' : '-'}</td>
                                                            <div className="flex-shrink-0 me-3">

                                                                {dobImg ? dobImg.image_url.startsWith("data:application/pdf") ?
                                                                    <a onClick={() => convertToPdfdobImg()} target='_black'> <img src={pdf} style={{ height: '10%', width: '10%', cursor: "pointer" }} /></a> :
                                                                    dobImg.image_url.includes(".pdf") ? <a href={dobImg ? dobImg.image_url ? dobImg.image_url : "" : ""} target='_black'> <img src={pdf} style={{ height: '10%', width: '10%', cursor: "pointer" }} /></a> :

                                                                    <Zoom >  <img className="d-flex-object rounded-circle avatar-xs" alt="" src={dobImg ? dobImg.image_url ? dobImg.image_url : "images/black22.jpg" : "images/black22.jpg"} /></Zoom> : null}
                                                            </div>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">State Bar Council :</th>
                                                            <td>{allData33 ? allData33.State_of_Bar_Council ? allData33.State_of_Bar_Council : '-' : '-'}</td>
                                                            <div className="flex-shrink-0 me-3">
                                                                {StateofBarCouncil ? StateofBarCouncil.image_url.startsWith("data:application/pdf") ?
                                                                    <a onClick={() => convertToPdfStateofBar()} target='_black'> <img src={pdf} style={{ height: '10%', width: '10%', cursor: "pointer" }} /></a> :
                                                                    StateofBarCouncil.image_url.includes(".pdf") ? <a href={StateofBarCouncil ? StateofBarCouncil.image_url ? StateofBarCouncil.image_url : "" : ""} target='_black'> <img src={pdf} style={{ height: '10%', width: '10%', cursor: "pointer" }} /></a> :


                                                                    <Zoom >  <img className="d-flex-object rounded-circle avatar-xs" alt="" src={StateofBarCouncil ? StateofBarCouncil.image_url ? StateofBarCouncil.image_url : "images/black22.jpg" : "images/black22.jpg"} /></Zoom> : null}
                                                            </div>
                                                        </tr>

                                                        {/* <tr>
                                                            <th scope="row">Confirmation Date Time:</th>
                                                            <td>{allData33 ? allData33.Confirmation_date_time ? allData33.Confirmation_date_time : '-' : '-'}</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row">Edit Date Time:</th>
                                                            <td>{allData33 ? allData33.Edit_confirmation_date_time ? allData33.Edit_confirmation_date_time : '-' : '-'}</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row">Maximum Marks:</th>
                                                            <td>{allData33 ? allData33.Maximum_Marks ? allData33.Maximum_Marks : '-' : '-'}</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row">Obtained Marks:</th>
                                                            <td>{allData33 ? allData33.Obtained_Marks ? allData33.Obtained_Marks : '-' : '-'}</td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>


                                        </div>
                                    </div>
                                    <button className=" btn btn-primary float-lg-end waves-effect waves-light" onClick={() => {localStorage.setItem("pages" , "0") ; history('/Editdetails') ; localStorage.setItem("ed" , "0")} } >
                                        {/* <i className="bx bx-edit font-size-17"></i> */}
                                         Edit Details
                                         </button>

                                </div>


                            </div> : '' }





                            {/* 
                            <div className="col-lg-6">

                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Bank Details</h4>
                                        <div className="table-responsive">
                                            <div className="product-detai-imgs">
                                                <div className="row">
                                                    <div className="col-md-2 col-sm-3 col-4">
                                                        <div className="nav flex-column nav-pills " id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                            <a className="nav-link active" id="product-1-tab" data-bs-toggle="pill" href="#product-1" role="tab" aria-controls="product-1" aria-selected="true">
                                                                <figure>
                                                                    <img src={allData22 ? allData22.Signature : "images/images(1).png"} alt="" className="img-fluid mx-auto d-block rounded" />
                                                                    <figcaption className='imageColor' style={{ "color": "black" }}> Aadhar Card</figcaption>
                                                                </figure>

                                                            </a>

                                                            <a className="nav-link" id="product-2-tab" data-bs-toggle="pill" href="#product-2" role="tab" aria-controls="product-2" aria-selected="false">
                                                                <figure>
                                                                    <img src={allData22 ? allData22.Certificate : "images/images(1).png"} alt="" className="img-fluid mx-auto d-block rounded" />
                                                                    <figcaption className='imageColor' style={{ "color": "black" }}> Pan Card</figcaption>
                                                                </figure>
                                                            </a>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 offset-md-1 col-sm-9 col-8">
                                                        <div className="tab-content" id="v-pills-tabContent">
                                                            <div className="tab-pane fade show active" id="product-1" role="tabpanel" aria-labelledby="product-1-tab">
                                                                <div className='show-img'>
                                                                    <img src={allData22 ? allData22.Signature : "images/images(1).png"} alt="" className="img-fluid mx-auto d-block" />
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade" id="product-2" role="tabpanel" aria-labelledby="product-2-tab">
                                                                <div className='show-img'>
                                                                    <img src={allData22 ? allData22.Certificate : "images/images(1).png"} alt="" className="img-fluid mx-auto d-block" />
                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div> */}

                        </div>
                    </div>
                </div>



                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                © All India Bar Examination  Rights Reserved {(new Date().getFullYear())}.
                            </div>
                            <div className="col-sm-6">
                                <div className="text-sm-end d-none d-sm-block">
                                    Powered by  <a href="https://www.nichetechsolutions.com/" target="_blank">Nichetech.in</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>



            <div className="right-bar">
                <div data-simplebar className="h-100">
                    <div className="rightbar-title d-flex align-items-center px-3 py-4">

                        <h5 className="m-0 me-2">Settings</h5>

                        <a className="right-bar-toggle ms-auto">
                            <i className="mdi mdi-close noti-icon"></i>
                        </a>
                    </div>

                    <hr className="mt-0" />
                    <h6 className="text-center mb-0">Choose Layouts</h6>

                    <div className="p-4">
                        <div className="mb-2">
                            <img src="images/layouts/layout-1.jpg" className="img-thumbnail" alt="layout images" />
                        </div>

                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input theme-choice" type="checkbox" id="light-mode-switch" />
                            <label className="form-check-label" htmlFor="light-mode-switch">Light Mode</label>
                        </div>

                        <div className="mb-2">
                            <img src="images/layouts/layout-2.jpg" className="img-thumbnail" alt="layout images" />
                        </div>
                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input theme-choice" type="checkbox" id="dark-mode-switch" />
                            <label className="form-check-label" htmlFor="dark-mode-switch">Dark Mode</label>
                        </div>

                        <div className="mb-2">
                            <img src="images/layouts/layout-3.jpg" className="img-thumbnail" alt="layout images" />
                        </div>
                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input theme-choice" type="checkbox" id="rtl-mode-switch" />
                            <label className="form-check-label" htmlFor="rtl-mode-switch">RTL Mode</label>
                        </div>

                        <div className="mb-2">
                            <img src="images/layouts/layout-4.jpg" className="img-thumbnail" alt="layout images" />
                        </div>
                        <div className="form-check form-switch mb-5">
                            <input className="form-check-input theme-choice" type="checkbox" id="dark-rtl-mode-switch" />
                            <label className="form-check-label" htmlFor="dark-rtl-mode-switch">Dark RTL Mode</label>
                        </div>


                    </div>

                </div>
            </div>



            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className='p-3 border-0' >
                    <Modal.Title>Reason for Rejection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-dialog modal-dialog-centered mt-0 mb-0" role="document">
                        <div className="modal-content">

                            <form>
                                <textarea className="form-control" rows="10" placeholder="Type Here" value={reason} onChange={handleReason} ></textarea>
                            </form>

                        </div>
                    </div>
                    {reasonError}

                </Modal.Body>
                <Modal.Footer className='border-0'>

                    <button type="button" className="btn btn-light" id="close" onClick={handleClose} >Close</button>
                    {reason == "" ?
                        <button type="button" className="btn btn-primary" onClick={Reject}  >Submit</button>
                        :
                        <button type="button" id="close" className="btn btn-primary" onClick={() => { modelClose(); Reject() }}>Submit</button>}

                </Modal.Footer>
            </Modal>

            <div className="rightbar-overlay"></div>


        </div>
    )
}

export default ProfileList;