import React, { useEffect } from 'react';
import AdminLogin from './Component/AdminLogin';
import FRlist from './Component/ProfileList';
import List from './Component/List';
import Report from './Component/Report';


import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Backend from './Component/Backend';
function App() {

  useEffect(() => {
    if (window.location.href == window.location.origin + '/') {
      if( localStorage.getItem('token1')){
        window.location.href = window.location.origin + '/List'
      }else{
        window.location.href = window.location.origin + '/Login'
      }      
    }
  }, [])
  return (
    <>
      <Router>
        <Routes>

          <Route exact path="/Login" element={<AdminLogin />} />
          <Route exact path="/List" element={<List />} />
          <Route exact path="/Userdetails" element={<FRlist />} />
          <Route exact path="/Report" element={<Report />} />
          <Route exact path="/Editdetails" element={<Backend />} />




        </Routes>
      </Router>
    </>
  );
}

export default App;
