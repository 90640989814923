import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Loader from './Loader';
import MainDashbord from './MainDashbord';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { candidateEditSubmitAPI, candidateEditAPI, getStatesAPI, dispatchLoadingStatus } from '../API/Action/globalAction';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';





function Backend() {

    let getStatesReg = useSelector(state => state.globalReducer.getStatesReg);
    let candidateEditReg = useSelector(state => state.globalReducer.candidateEditReg);
    let candidateEditSubmitReg = useSelector(state => state.globalReducer.candidateEditSubmitReg);
    const dispatch = useDispatch({

    });
    let history = useNavigate();

    let token = localStorage.getItem('token1');
    let rollNumber = localStorage.getItem('roll');

    const [Mobile, setMobile] = useState("");
    const [Name, setName] = useState("");

    let pageOn = localStorage.getItem("ed");

    const [startDate, setStartdate] = useState(null);
    const [candidateError, setCandidateError] = useState("");
    const [nameError, setNameError] = useState("");
    const [fullDetailsError, setFullDetailsError] = useState("");
    const [dateError, setDateError] = useState("");
    const [StateBarError, setStateBarError] = useState("");

    const [nameError22, setNameError22] = useState(false);
    const [fullDetailsError22, setFullDetailsError22] = useState(false);
    const [dateError22, setDateError22] = useState(false);
    const [StateBarError22, setStateBarError22] = useState(false);


    const [StateBar, setStateBar] = useState([{ label: "Select", value: "" }]);
    const [fullDetails, setFullDetails] = useState("");

    useEffect(() => {
        const formattedDate = moment(candidateEditReg ? candidateEditReg.Date_of_Birth : "", "DD-MM-YYYY");
        const jsDateObject = formattedDate.isValid() ? formattedDate.toDate() : null;

        setStartdate(jsDateObject);
        setFullDetails(candidateEditReg ? candidateEditReg.Father_Name : "");
        setName(candidateEditReg ? candidateEditReg.Candidate_Name : "");
        setStateBar(candidateEditReg ? [{ label: candidateEditReg.State_of_Bar_Council, value: candidateEditReg.State_of_Bar_Council }] : [{ label: "Select", value: "" }])

    }, [candidateEditReg]);

    function handleMobile(e) {
        console.log("mobileee", e.target.value);
        if (e) {

            setMobile(e.target.value.trim() == "" ? "" : e.target.value);
        }
        else {
            setMobile("");

        }
    }

    useEffect(() => {



        let data = {
            'token': token ? token : "",
        }

        let dataCandidate = {
            'token': token ? token : "",
            'roll_no': rollNumber ? rollNumber : ""
        }
        dispatch(getStatesAPI(data));
        dispatch(candidateEditAPI(dataCandidate));
        dispatch(dispatchLoadingStatus(true));

        if (token && pageOn == '0') {
            if (!window.location.href.includes('Editdetails')) {
                window.location.href = window.location.origin + '/Editdetails';
            }
        }
        else {
            if (!window.location.href.includes('/List')) {
                window.location.href = window.location.origin + '/List';
            }
        }



    }, [])

    const maxDate = moment().subtract(18, 'years').toDate();

    function handleSubmit() {

        let nameRe = /^[a-zA-Z\s]*$/;

        if (Name) {

            if (Name.length >= 2) {
                setNameError("");
                setNameError22(false);


                if (!nameRe.test(Name)) {
                    setNameError("Please enter valid candidate name");
                    setNameError22(true);

                }
                else {
                    setNameError("");
                    setNameError22(false);


                }
            }
            else {
                setNameError("Please enter valid candidate name");
                setNameError22(true);


            }
        }
        else {
            setNameError("Please enter candidate name");
            setNameError22(true);


        }


        if (fullDetails) {

            if (fullDetails.length >= 2) {
                setFullDetailsError("");
                setFullDetailsError22(false);

                if (!nameRe.test(fullDetails)) {
                    setFullDetailsError("Please enter valid S/o / D/o / W/o");
                    setFullDetailsError22(true);

                }
                else {
                    setFullDetailsError("");
                    setFullDetailsError22(false);

                }
            }
            else {
                setFullDetailsError("Please enter valid S/o / D/o / W/o");
                setFullDetailsError22(true);

            }
        }
        else {
            setFullDetailsError("Please enter S/o / D/o / W/o");
            setFullDetailsError22(true);

        }

        if (startDate) {
            setDateError("");
            setDateError22(false);

        }
        else {
            setDateError("Please enter date of birth");
            setDateError22(true);

        }

        if (StateBar) {
            setStateBarError("");
            setStateBarError22(false);

        }
        else {
            setStateBarError("Please enter state bar council");
            setStateBarError22(true);

        }


        if ( StateBarError22 == false && nameError22 == false && fullDetailsError22 == false && StateBarError22 == false && Name && StateBar && startDate && fullDetails ) {

            let data = {
                'token': token ? token : "",
                'roll_no' : rollNumber,
                'dob': moment(startDate).format("DD-MM-yyyy"),
                'candidate_name': Name,
                'father_name': fullDetails,
                'enrollment_no': candidateEditReg ? candidateEditReg.Enrollment_Certificates : "",
                'state_of_bar_council': StateBar ? StateBar.value ? StateBar.value : candidateEditReg ? candidateEditReg.State_of_Bar_Council : "" : candidateEditReg ? candidateEditReg.State_of_Bar_Council : ""
            }
            dispatch(candidateEditSubmitAPI(data));
            dispatch(dispatchLoadingStatus(true));
        }


    }

    function handleCan (e) {
        let data = e.target.value; 
        setName(e.target.value.trim() == "" ? "" : e.target.value)
        let nameRe = /^[a-zA-Z\s]*$/;


        if (data) {

            if (data.length >= 3) {
                setNameError22(false);


                if (!nameRe.test(data)) {
                    setNameError22(true);

                }
                else {
                    setNameError22(false);
                }
            }
            else {
                setNameError22(true);
            }
        }
        else {
            setNameError22(true);
        }
    }

    function handleFull (e) {
        let data = e.target.value; 

        setFullDetails(e.target.value.trim() == "" ? "" : e.target.value)

        let nameRe = /^[a-zA-Z\s]*$/;

        if (data) {

            if (data.length >= 3) {
                setFullDetailsError22(false);

                if (!nameRe.test(data)) {
                    setFullDetailsError22(true);

                }
                else {
                    setFullDetailsError22(false);
                }
            }
            else {
                setFullDetailsError22(true);
            }
        }
        else {
            setFullDetailsError22(true);
        }
    }

    function handleDate (e) {
        let data = e; 
        setStartdate(data);

        if (data) {
            setDateError22(false);

        }
        else {
            setDateError22(true);
        }
    }

    function handleSub (e) {

console.log("eeeee" , e);
        let data = e; 
        setStateBar(e);


        if (data) {
            setStateBarError22(false);

        }
        else {
            setStateBarError22(true);

        }
    }
    return (
        <>
            <div id="layout-wrapper">
                <Loader />

                <MainDashbord />
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row"><div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18"> Edit Details </h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <a className="breadcrumb-item active" href="/Editdetails" >Edit Details</a><a href="/List" className="breadcrumb-item active" >List</a>


                                        </ol></div>
                                </div></div></div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="table-rep-plugin">



                                                <div className="row no-gutters">

                                                    <div className="col-md-4">
                                                        <div className="mb-3">
                                                            <label htmlFor="validationCustom05" className="form-label">Application ID *</label>
                                                            <input type="text" className="form-control" placeholder="Enter Application ID" value={candidateEditReg ? candidateEditReg.Application_ID : ""} disabled maxLength='10'  ></input>

                                                        </div>

                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="mb-3">
                                                            <label htmlFor="validationCustom05" className="form-label">Roll Number *</label>
                                                            <input type="text" className="form-control" placeholder="Enter Roll Number" value={candidateEditReg ? candidateEditReg.Roll_Number : ""} disabled maxLength='10' onChange={(e) => handleMobile(e)} ></input>

                                                        </div>

                                                    </div>


                                                    <div className="col-md-4">
                                                        <div className="mb-3">
                                                            <label htmlFor="validationCustom05" className="form-label">Candidate Name *</label>
                                                            <input type="text" className="form-control" placeholder="Enter Candidate Name" value={Name} onChange={(e) => handleCan(e)} maxLength={30} />
                                                            <span style={{ "color": "#BE312E" }}>{nameError} </span>

                                                        </div>

                                                    </div>


                                                    <div className="col-md-4">
                                                        <div className="mb-3">
                                                            <label htmlFor="validationCustom05" className="form-label">S/o / D/o / W/o *</label>
                                                            <input type="text" className="form-control" placeholder="Enter S/o / D/o / W/o " value={fullDetails} onChange={(e) => handleFull(e)} maxLength={30} />
                                                            <span style={{ "color": "#BE312E" }}>{fullDetailsError} </span>
                                                        </div>

                                                    </div>



                                                    <div className="col-md-4">
                                                        <div className="mb-4">
                                                            <label className='w-100'>Date Of Birth *</label>
                                                            <DatePicker
                                                                className="form-control w-100"
                                                                dateFormat="dd/MM/yyyy"
                                                                onChange={(value) => { handleDate(value) }}
                                                                selected={startDate ? startDate : null}
                                                                placeholderText={"dd/mm/yyyy"}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                scrollableYearDropdown
                                                                yearDropdownItemNumber={moment().year() - 1900}
                                                                maxDate={maxDate}
                                                                candidateEditReg
                                                                style={{width: '100% !important'}}
                                                            />
                                                            <span style={{ "color": "#BE312E" }}>{dateError} </span>

                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label">State Bar Council *</label>

                                                            <Select
                                                                options={getStatesReg ? getStatesReg.map(e => ({ label: e.State, value: e.State })) : [{ label: "Select", value: "" }]}
                                                                isClearable={true}
                                                                onChange={(e) => handleSub(e)}
                                                                value={StateBar}

                                                            />
                                                            <span style={{ "color": "#BE312E" }}>{StateBarError} </span>


                                                        </div>

                                                    </div>





                                                    <div className="col-md-12">
                                                        <div className="text-center d-block m-auto">
                                                            <button type="button" className="btn btn-primary btn-md waves-effect waves-light" onClick={() => handleSubmit()} data-bs-toggle="modal" data-bs-target=".transaction-detailModal" >
                                                                Submit
                                                            </button>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    © All India Bar Examination  Rights Reserved {(new Date().getFullYear())}.
                                </div>
                                <div className="col-sm-6">
                                    <div className="text-sm-end d-none d-sm-block">
                                        Powered by  <a href="https://www.nichetechsolutions.com/" target="_blank">Nichetech.in</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>




            </div>
        </>
    )
}

export default Backend