
import {
  LoginReg,
  confirmUserReg,
  userdetailsReg, AcceptReg, RejectReg , filterUserReg , getStatesReg , candidateEditReg , candidateEditSubmitReg
} from '../../Constant';

let defaultState = {

  LoginReg: null,
  confirmUserReg: null,
  userdetailsReg: null,
  AcceptReg: null,
  RejectReg: null,
  filterUserReg:null,
  getStatesReg : null,
  candidateEditReg : null,
  candidateEditSubmitReg : null

}

const globalReducer = (state = defaultState, action) => {

  switch (action.type) {
    case LoginReg:
      return Object.assign({}, state, {
        LoginReg: action.payload
      });

    case confirmUserReg:
      return Object.assign({}, state, {
        confirmUserReg: action.payload
      });
    case userdetailsReg:
      return Object.assign({}, state, {
        userdetailsReg: action.payload
      });
    case AcceptReg:
      return Object.assign({}, state, {
        AcceptReg: action.payload
      });
    case RejectReg:
      return Object.assign({}, state, {
        RejectReg: action.payload
      });
      case filterUserReg:
        return Object.assign({}, state, {
          filterUserReg: action.payload
        });
        case getStatesReg:
          return Object.assign({}, state, {
            getStatesReg: action.payload
          });

          case candidateEditReg:
            return Object.assign({}, state, {
              candidateEditReg: action.payload
            });

            case candidateEditSubmitReg:
              return Object.assign({}, state, {
                candidateEditSubmitReg: action.payload
              });
            
          


    default:
      return state;
  }
};
export default globalReducer;

