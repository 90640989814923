import React, { useEffect , useRef , useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useNavigate } from "react-router-dom";

// Added by Parth Roka
function MainDashbord() {
    let history = useNavigate();
    let pages = localStorage.getItem("pages");

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
    const dropdownRef = useRef(null);
    const [openmenu, setopenmenu] = useState(window.navigator.userAgent.toLowerCase().includes("mobi") ? false : true);
    const [subMenu, setSubMenu] = useState(window.location.href.includes('/FreelancerRoleManagement') || window.location.href.includes('/FreelancerAssignRole') || window.location.href.includes('/Freelancers') ? true : false)

    function LogOut() {
        setIsOpen(false)
        confirmAlert({
            // title: 'Confirm to submit',
            message: 'Are you sure you want to logout?',
            buttons: [
                {
                    label: 'Cancel',
                    onClick: () => { }
                },
                {
                    label: 'Ok',
                    onClick: () => {
                        // localStorage.clear();
                        localStorage.removeItem('token1')
                        localStorage.removeItem('projapicall')
                        localStorage.removeItem('emailAdmin')
                        localStorage.setItem("projectTitle", "")
                        localStorage.setItem("projectTitle22", "Project");
                        history("/Login")
                    }

                }
            ]


        });
        //      
    }

    useEffect(() => {
        if (window.navigator.userAgent.toLowerCase().includes("mobi")) {
            document.body.classList.add('sidebar-main')
        } else {
            document.body.classList.remove('sidebar-main')
        }

        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
              alert("hiii")
              setIsOpen(false);
            }
          }
      
          document.addEventListener('mousedown', handleClickOutside);
          return () => {
            document.removeEventListener('mousedown', handleClickOutside);
          };
  
    }, [])


    function setopenmenucall() {
        if (window.navigator.userAgent.toLowerCase().includes("mobi")) {
            if (openmenu) {
                document.body.classList.remove('sidebar-main')
            } else {
                document.body.classList.add('sidebar-main')
            }
        } else {
            if (openmenu) {
                document.body.classList.add('sidebar-main')
            } else {
                document.body.classList.remove('sidebar-main')
            }
        }
        setopenmenu(!openmenu)
    }

    return (
        <div>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">

                        <div className="navbar-brand-box">
                            <a href="/List" className="logo ">
                                <span className="logo-lg">
                                    <img className="logo" src="images/AIBELOGO.png" alt="" style={{ height: "20%", width: "30%" }} />
                                </span>
                            </a>
                        </div>

                        <button onClick={() => setopenmenucall()} type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                            <i className="fa fa-fw fa-bars"></i>
                        </button>

                    </div>

                    <div className="d-flex">
                        <div className="dropdown d-inline-block">
                            <button
                                type="button"
                                className="btn header-item waves-effect"
                                id="page-header-user-dropdown"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded={isOpen}
                                onClick={toggleDropdown}

                            >
                                <span className="d-none d-xl-inline-block ms-1" key="t-henry">
                                    {localStorage.getItem('emailAdminn')}
                                </span>
                                <i
                                    className="mdi mdi-chevron-down d-none d-xl-inline-block"
                                ></i>
                            </button>

                            <div
                                className={`dropdown-menu dropdown-menu-end${isOpen ? ' show' : ''}`}
                            >
                                <a className="dropdown-item text-danger" onClick={LogOut}>
                                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                                    <span key="t-logout">Logout</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {openmenu && <div className="vertical-menu">
                <div data-simplebar className="h-100">

                    <div id="sidebar-menu" className="mm-active">

                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title" key="t-menu">Menu</li>

                            <li className={window.location.href.includes('/List') || pages == '0' || subMenu ? "mm-active" : ''} >
                                <a className="" aria-expanded="true" onClick={()=> window.location.href = window.location.origin + '/List'}  >
                                    <i className="bx bx-user"></i>
                                    <span key="t-invoices">User List</span>
                                </a>

                            </li>
                            <li className={window.location.href.includes('/Report') || pages == '1' || subMenu ? "mm-active" : ''} >
                                <a className="" aria-expanded="true" onClick={()=> window.location.href = window.location.origin + '/Report'}  >
                                    <i className="bx bx-file"></i>
                                    <span key="t-invoices">Report</span>
                                </a>

                            </li>

                        </ul>
                    </div>
                </div>
            </div>}

        </div>
    )
}


export default MainDashbord;